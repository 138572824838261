import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Paragraph, Row} from "../../components/atomics"
import colors from "../../colors"
import { useLanguage } from "../../hooks"
import deleteAccount1 from '../../assets/images/app/delete-account-1.jpeg'
import deleteAccount2 from '../../assets/images/app/delete-account-2.jpeg'
const text = {
  title: {
    en: 'Delete Account',
    pt: 'Excluir Conta'
  },
  s1: {
    en: 'How to delete all your data',
    pt: 'Como excluir todos os seus dados'
  },
  's1.p1': {
    en: `If you want to delete your account and all its data, just follow the steps below. Before continuing, remember that this action is irreversible, that is, once you delete your data it is not possible to recover them again. The first step is to enter the application account, after that, go to the general tab.`,
    pt: `Se você deseja excluir sua conta e todos os seus dados, basta seguir os passos seguintes. Antes de continuar, lembre-se que está ação é irreversível, ou seja, uma vez que excluir seus dados não é possível recupera-lós novamente. O primeiro passo é entrar na conta conta do aplicativo, após isso, vá na aba geral.`,
  },
  's1.p2': {
    en: 'When you click on the general tab, the DELETE ACCOUNT option will appear in the PROFILE section. Click the DELETE ACCOUNT button. After that, a confirmation message will appear as shown in the image below. To confirm that you want to delete your account, just click on the YES option.',
    pt: 'Ao clicar na aba geral, irá aparecer a opção EXCLUIR CONTA, na seção PERFIL. Clique no botão EXCLUIR CONTA. Após isso, irá aparecer uma mensagem de confirmação conforme a imagem abaixo. Para confirmar que deseja excluir sua conta, basta clicar na opção SIM.',
  },
}

const PrivacyPolicy = () => {
  const {language,setLanguage} = useLanguage();
  return(
  <Layout>
    <SEO title={text['title'][language]} />
    <br/>
    <Row className="column-in-mobile" style={{justifyContent:'space-between'}}> 
      <h1 style={{marginBottom:10}}>{text['title'][language]}</h1>
      <Row style={{alignItems:'center'}}>
        <h5 style={{marginRight:10}}>Language:</h5>
        <button onClick={()=> setLanguage('en')} style={{border:'none', padding:'5px 10px',marginRight:10,background: language === 'en' ? colors.degrade : '#aaa', color: 'white',cursor:'pointer'}}><h5>EN</h5></button>
        <button onClick={()=> setLanguage('pt')} style={{border:'none', padding:'5px 10px',marginRight:10,background: language === 'pt' ? colors.degrade : '#aaa', color: 'white',cursor:'pointer'}}><h5>PT</h5></button>
      </Row>
    </Row>
    <br/>
    <br/>
    <h3>{text['s1'][language]}</h3>
    <Paragraph>
      {text['s1.p1'][language]}
    </Paragraph>
      <img src={deleteAccount1} style={{height: 350, objectFit: 'contain'}}  alt="delete-account" />
    <Paragraph>
      {text['s1.p2'][language]}
    </Paragraph>
     <img src={deleteAccount2} style={{height: 350, objectFit: 'contain'}}  alt="delete-account" />
    <br/>
  </Layout>
)}

export default PrivacyPolicy
